import Vue from 'vue'
import linkify from 'linkifyjs/html'
import _ from 'lodash'

Vue.directive('chat-content', (el, binding) => {
  const options = {
    ...binding.value,
    target: (href, type) => {
      if (type !== 'url') return

      if (!_.startsWith(href, window.location.origin)) {
        return '_blank'
      }
    }
  }
  el.innerHTML = linkify(el.innerHTML, options)
})
